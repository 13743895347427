import React from 'react';
import './style.scss';
import kawhiPlaceholder from '../../assets_global/images/kawhi-placeholder.png';
import install1 from '../../assets_global/images/install-1.png';
import install2 from '../../assets_global/images/install-2.png';
import install3 from '../../assets_global/images/install-3.png';
import insta_1 from '../../assets_global/images/insta-1.png';
import insta_2 from '../../assets_global/images/insta-2.png';
import insta_3 from '../../assets_global/images/insta-3.png';
import insta_4 from '../../assets_global/images/insta-4.png';
import insta_5 from '../../assets_global/images/insta-5.png';
import insta_6 from '../../assets_global/images/insta-6.png';
import insta_7 from '../../assets_global/images/insta-7.png';
import insta_8 from '../../assets_global/images/insta-8.png';
import insta_9 from '../../assets_global/images/insta-9.png';
import insta_10 from '../../assets_global/images/insta-10.png';
import insta_11 from '../../assets_global/images/insta-11.png';
import insta_12 from '../../assets_global/images/insta-12.png';
import insta_13 from '../../assets_global/images/insta-13.png';
import insta_14 from '../../assets_global/images/insta-14.png';
import insta_15 from '../../assets_global/images/insta-15.png';
import insta_16 from '../../assets_global/images/insta-16.png';
import insta_17 from '../../assets_global/images/insta-17.png';
import insta_18 from '../../assets_global/images/insta-18.png';

const PhotoBlock = () => (

    <div className="photo-block">
       {/*  <img src={kawhiPlaceholder} alt="Ka'wine & Dine badge in restaurant" />
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={install1} alt="Ka'wine & Dine badge in restaurant" /></a>
        <img src={kawhiPlaceholder} alt="Ka'wine & Dine badge in restaurant" />

        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={install2} alt="Ka'wine & Dine badge in restaurant" /></a>
        <img src={kawhiPlaceholder} alt="Ka'wine & Dine badge in restaurant" />
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={install3} alt="Ka'wine & Dine badge in restaurant" /></a>
 */}

        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_16} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_2} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_3} alt="Ka'wine & Dine badge in restaurant" /></a>

        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_4} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_5} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_6} alt="Ka'wine & Dine badge in restaurant" /></a>

        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_13} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_8} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_9} alt="Ka'wine & Dine badge in restaurant" /></a>

        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_11} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_7} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_14} alt="Ka'wine & Dine badge in restaurant" /></a>

        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_10} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_15} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_12} alt="Ka'wine & Dine badge in restaurant" /></a>

        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_18} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_17} alt="Ka'wine & Dine badge in restaurant" /></a>
        <a href="https://www.instagram.com/kawineanddine" title="Ka'wine & Dine" target="_blank"><img src={insta_1} alt="Ka'wine & Dine badge in restaurant" /></a>
    </div>

)
export default PhotoBlock;