import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import logo from './logo.svg';
import './assets_global/scss/normalize.scss';
import './assets_global/scss/styles.scss';
import Home from './pages/home/home';


function App() {
  return (
    <Router>        
          <Switch>
            <Route
              exact
              path='/'
              render={(props) => <Home />}
            />
            
            <Route
            exact
              path='home(/)'
              render={(props) => <Home />}
            />
            {/* <Route exact path="/" component={HomePageEn} ageCookie={this.state.ageCookie} />
            <Route path="en(/)" component={HomePageEn} ageCookie={this.state.ageCookie} /> 
            <Route path="en/home" component={HomePageEn} ageCookie={this.state.ageCookie} />*/}
            
            {/*IF NO MATCH REDIRECT EN HOMEPAGE*/}
            <Route
              render={(props) => <Home {...props}  />}
            /> 
          </Switch>
    </Router>
  );
}

export default App;
