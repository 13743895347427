import React from 'react';
import './style.scss';
import { FacebookShareButton, TwitterShareButton, FacebookIcon,  TwitterIcon } from 'react-share';
import imgTwitter from '../../assets_global/images/icon-tw-gold.png';
import imgInstagram from '../../assets_global/images/icon-ig-gold.png';
import imgFacebook from '../../assets_global/images/icon-fb-gold.png';
import imgKawhiBadge from '../../assets_global/images/Kawine&DineFBpost.jpg';
import btnFacebook from '../../assets_global/images/btn-facebook.png';
import btnTwitter from '../../assets_global/images/btn-twitter.png';

class SocialShare extends React.Component {
    render() {
        return (
            <div className="social-block">
                {/* 
                <a className="download btn" href={imgKawhiBadge} target="" download="no-sultan.3c11ae03.jpg">
                    <img src={imgInstagram} alt="instagram logo" />
                </a> */}

                <FacebookShareButton className="btn facebook" url="www.kawineanddine.com" quote="Rally this city. Join the Ka’wine & Dine for Kawhi Leonard."  >
                     <img src={btnFacebook} className="icon-facebook"/> 
                </FacebookShareButton>

                <TwitterShareButton className="btn btn-twitter" url="https://www.kawineanddine.com" title="Rally this city. Join the Ka’wine & Dine for Kawhi Leonard. #Kawineanddine" >
                    <img src={btnTwitter} className="icon-twitter"/>
                </TwitterShareButton>

            </div>
        )
    }
}
export default SocialShare;