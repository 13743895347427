import React from 'react';
import PropTypes from "prop-types";
import './style.scss';

const Layout = ({children}) => (

    <div className="wrapper">
       {children}
    </div>

)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
  
export default Layout;