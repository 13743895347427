import React from 'react';
import Layout from '../../components/Layout/Layout';
import './style.scss';
import raptorsRepublic from '../../assets_global/images/raptors-republic.png';
import raptorsClaw from '../../assets_global/images/republic-claw.png';
import kawhiLogo from '../../assets_global/images/kawine-logo.png';
import arrowCircle from '../../assets_global/images/arrow-circle.png';
import rallyCity from '../../assets_global/images/rally-city.png';
import SocialShare from '../../components/SocialShare/SocialShare';
import imgKawhiBadge from '../../assets_global/images/KaWineAndDine_Badge.jpg';
import downloadCarImg from '../../assets_global/images/rides-free-share-badge.png';
import downloadCarBadge from '../../assets_global/images/KaWineAndDine_Badge_rides.jpg';
import downloadShopsImg from '../../assets_global/images/shops-share-badge.png';
import downloadShopsBadge from '../../assets_global/images/KaWineAndDine_Badge_shops.jpg';
import downloadEatsBadge from '../../assets_global/images/KaWineAndDine_eats_Badge.jpg';
import downloadStayImg from '../../assets_global/images/support-share-badge.png';
import downloadStayBadge from '../../assets_global/images/KaWineAndDine_Badge_stay.jpg';
import downloadButton from '../../assets_global/images/download-badge.png';
import downloadGiveForKawhi from '../../assets_global/images/KaWineAndDine_Badge_give_back.png';
import imgGiveForKawhiBadge from '../../assets_global/images/give-share-badge.png';
import imgEatsFree from '../../assets_global/images/eats-free-share-badge.png';
import PhotoBlock from '../../components/PhotoBlock/PhotoBlock';
import logo1 from '../../assets_global/images/logo-1.png';
import logo2 from '../../assets_global/images/logo-2.png';
import logo3 from '../../assets_global/images/logo-3.png';
import logo4 from '../../assets_global/images/logo-4.png';

class Home extends React.Component {
    render() {
        return (
            <Layout>
                <div className="block-1">
                    <div className="top-line"></div>
                    <span className="circle">
                        <a href="https://www.raptorsrepublic.com/" title="Raptors Republic" target="_blank"><img src={raptorsRepublic} alt="In association with Raptors Republic" /></a>
                    </span>

                </div>

                <div className="block-2">
                    <div className="container">
                        <img src={kawhiLogo} className="kawhi-logo" alt="ka' wine and dine - logo" />
                        <h1>Wine’m, Dine’m, <br className="break-m" />Let’s re-sign’m.</h1>
                        <p>Introducing the Ka’wine & Dine <br className="break" />Toronto restaurants offering Kawhi Leonard<br className="break" /> free food for life if he re-signs with Toronto.</p>
                        <span className="line"></span>
                        {/* <p className="download">Download the Ka’wine & Dine badge below.</p> */}
                    </div>
                </div>

               {/*  <div className="block-3">
                    <div className="container">
                        <a className="box" download="KaWineAndDine_Badge.2ceb96f4.jpg" href={imgKawhiBadge} title="Ka'Wine And Dine Badge">
                             <h3>Join the cause.</h3>
                            <p>Simply download the badge and place it in your restaurant window.<a className="download" href={imgKawhiBadge} target="" download="KaWineAndDine_Badge.2ceb96f4.jpg">(Download now)</a></p>
                         
                            <h4>Click To Download Badge</h4>
                            <img src={downloadButton} alt="Download Ka'wine & Dine Badge" />
                         </a>
          
                         <h2>Join the cause.</h2>
                        <p>Click <a className="download" href={imgKawhiBadge} target="" download="no-sultan.3c11ae03.jpg">here</a> to download the Ka’wine & Dine badge for your restaurant window.</p>
                        <span className="line"></span> 
                    </div>
                </div> */}

                <div className="block-badges-added">
                    <div className="container">
                        <h2>Download your badge:</h2>
                        <p>Show your support for Kawhi. Download your badge now.</p>
                        
                        <div className="badgewrap">
                            
                            <a className="badge" download="KaWineAndDine_eats_Badge.c96b6d38.jpg" href={downloadEatsBadge} title="Ka'Wine And Dine Shops Badge">
                            {/*  <h4>Shops Badge</h4> */}
                                <img src={imgEatsFree} alt="Download Stay Badge" title="Ka'Wine And Dine Stay Badge" />
                            </a>
                            <a className="badge" download="KaWineAndDine_Badge_rides.42ac238d.jpg" href={downloadGiveForKawhi} title="Ka'Wine And Dine Car Badge">
                                {/* <h4>Car Share Badge</h4> */}
                                <img src={imgGiveForKawhiBadge} alt="Download Ka'wine & Dine Car Badge" />
                            </a>                         
                            <a className="badge" download="KaWineAndDine_Badge_rides.42ac238d.jpg" href={downloadCarBadge} title="Ka'Wine And Dine Car Badge">
                                {/* <h4>Car Share Badge</h4> */}
                                <img src={downloadCarImg} alt="Download Ka'wine & Dine Car Badge" />
                            </a>
                            <span>
                                <a className="badge" download="KaWineAndDine_Badge_shops.a6bf61c0.jpg" href={downloadShopsBadge} title="Ka'Wine And Dine Shops Badge">
                                {/*  <h4>Shops Badge</h4> */}
                                    <img src={downloadShopsImg} alt="Download Shops Badge" title="Ka'Wine And Dine Shops Badge" />
                                </a>
                                <a className="badge" download="KaWineAndDine_Badge_stay.5db183c8.jpg" href={downloadStayBadge} title="Ka'Wine And Dine Shops Badge">
                                {/*  <h4>Shops Badge</h4> */}
                                    <img src={downloadStayImg} alt="Download Stay Badge" title="Ka'Wine And Dine Stay Badge" />
                                </a>
                            </span>
                              
                            
                         </div>
                         <h2>Wine and Dine’m.</h2>
                            <p>By placing the badge in your restaurant window, you pledge to offer Kawhi free food for life if he re-signs with Toronto.</p>
                            <span className="line"></span>
                            
                    </div>
                </div>

                <div className="block-kawhi-kind">
                    <div className="container">
                        <h2>KA’WINE & KIND</h2>
                        <p>Toronto the good. Show your support for Kawhi by donating on behalf of yourself or your business.</p>
                       {/*  <a className="box" download="KaWineAndDine_Badge.2ceb96f4.jpg" href={downloadGiveForKawhi} title="Ka'Wine And Dine Badge">
                            <img src={imgGiveForKawhiBadge} alt="Download Ka'wine & Dine Badge" />
                        </a> 
                      */}
                        <p>Please visit one of these great organizations and donate.</p>
                        <ul className="logo-list">
                            <li><a href="https://www.rexdalechc.com/programs/programs-for-youth-children" title="rexdalechc" target="_blank"><img src={logo1} title=""/></a></li>
                            <li><a href="https://www.dailybread.ca" title="daily bread" target="_blank"><img src={logo2} title=""/></a></li>
                            <li><a href="http://www.emilyshouse.ca/" title="emilyhouse" target="_blank"><img src={logo3} title=""/></a></li>
                            <li><a href="https://www.bgccan.com/en/" title="bgccan" target="_blank"><img src={logo4} title=""/></a></li>
                        </ul>
                        <span className="line"></span>


                        <h2>Spread the word.</h2>
                        <p>Share a video or image of the badge in your window on social using <span>#KaWineAndDine</span>&nbsp;<span>#HeStay</span></p>
                        <span className="line"></span>

                    </div>
                </div>

                <div className="block-rally">
                    <div className="container">
                        <img src={rallyCity} className="rally-city" alt="" />
                        <h2>Rally the city.</h2>
                        <p>Help us spread the word in social using <span>#KaWineAndDine</span>. Got a photo or video of a badge in the window? Share it on Instagram, Twitter or Facebook</p>
                        <span className="line"></span>
                        <PhotoBlock />
                       
                        <span className="line"></span>

                        <SocialShare />
                    </div>
                </div>

                <div className="block-5">
                    {/* <span className="circle">
                        <img src={raptorsClaw} alt="In association with Raptors Republic" />
                    </span> */}
                    <div className="bottom-line"></div>
                </div>

            </Layout>
        )
    }
}
export default Home;